import { Select } from '@mdb/flora';
import { sortBoxStyles } from './styles';
import { defaultSortByType, SortBoxProps, SortByType } from './types';
import { translateSortByOptions } from '../../utils/locale';

const SortBox: React.FunctionComponent<SortBoxProps> = ({
    onSort,
    sortBy,
    extraStyles = {},
    contentType,
}) => {
    const options: SortByType[] = ['Latest', 'Highest Rated'];
    let defaultSort: SortByType = defaultSortByType;

    if (contentType === 'Video') {
        defaultSort = 'Highest Rated';
    }

    return (
        <Select
            sx={{
                ...sortBoxStyles,
                ...extraStyles,
            }}
            label="Sort by"
            name="sort-by-dropdown"
            options={options}
            value={sortBy || defaultSort}
            onSelect={(value?: string) => {
                const translatedValue = translateSortByOptions(value || '');
                console.log(value, translatedValue);
                onSort(translatedValue);
            }}
        />
    );
};

export default SortBox;
